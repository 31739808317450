import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { LoaderFunctionArgs } from "@remix-run/node";
import {
  isRouteErrorResponse,
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { dehydrate, QueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";
import { getCurrentUser } from "./features/auth/api/auth-querries";
import { Provider } from "./lib/provider";
import { getSession } from "./lib/session";
import "./tailwind.css";

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = "sr"; //await i18next.getLocale(request);
  const session = await getSession(request.headers.get("Cookie"));
  const access_token = session.get("access_token") || undefined;
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 5 } },
  });

  if (access_token) {
    await Promise.all([
      queryClient.prefetchQuery(getCurrentUser(access_token)),
      queryClient.setQueryData(["access_token"], { access_token }),
    ]);
  }

  return json({ locale, dehydratedState: dehydrate(queryClient) });
}

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
};

export function Layout({ children }: { children: React.ReactNode }) {
  // Get the locale from the loader
  const data = useRouteLoaderData<typeof loader>("root");
  const locale = data?.locale || "en";

  const { i18n } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return (
    <Provider>
      <Outlet />
    </Provider>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <>
      <h1>Error!</h1>
      <p>{"Unknown error"}</p>
    </>
  );
}
