"use client";
import { useNavigate } from "@remix-run/react";
import { captureException } from "@sentry/react";
import { withScope } from "@sentry/remix";
import {
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useDehydratedState } from "use-dehydrated-state";
import { TooltipProvider } from "~/components/ui/tooltip";

// Type guard to check if an error is an AxiosError
function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

function Provider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (err, _variables, _context, mutation) => {
            withScope((scope) => {
              scope.setContext("mutation", {
                mutationId: mutation.mutationId,
                variables: mutation.state.variables,
              });
              if (mutation.options.mutationKey) {
                scope.setFingerprint(
                  // Duplicate to prevent modification
                  Array.from(mutation.options.mutationKey) as string[]
                );
              }
              captureException(err);
            });
          },
        }),
        queryCache: new QueryCache({
          onError: (err, query) => {
            withScope((scope) => {
              scope.setContext("query", { queryHash: query.queryHash });
              scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, "0")]);
              captureException(err);

              if (isAxiosError(err) && err.response?.status === 401) {
                navigate("/auth/signin");
              }
            });
          },
        }),
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 5000,
          },
          mutations: {
            onError: (err) => {
              console.log(err);
            },
          },
        },
      })
  );
  const dehydratedState = useDehydratedState();

  return (
    <>
      <TooltipProvider>
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={dehydratedState}>
            {children}
          </HydrationBoundary>
        </QueryClientProvider>
      </TooltipProvider>
    </>
  );
}

export { Provider };
